import React, { useState } from 'react';
import axios from 'axios';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    console.log('Form Data Updated', formData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting form with data:', formData);
    try {
      const response = await axios.post('http://localhost:5001/api/submit_contact', formData);
      console.log('API response:', response.data);
      alert('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }
  };

  return (
    <main className="flex flex-col p-4">
      <h1 className="text-white text-2xl text-center font-mono m-10">Get in touch</h1>
      <div className="flex flex-col lg:flex-row items-center gap-12 px-12 mt-5 lg:max-w-screen-lg lg:mx-auto">
        <form onSubmit={handleSubmit} className="contact-form items-center w-full lg:w-1/2">
          <label htmlFor="full_name" className="block text-white font-sans p-2 required">Name <span className="text-red-500">*</span></label>
          <input type="text" id="full_name" name="full_name" placeholder="Full name" required className="block w-full p-2 mb-6 border-b border-gray-500 bg-transparent text-white outline-none" onChange={handleChange} />
          
          <label htmlFor="email" className="block text-white font-sans p-2 required">E-Mail <span className="text-red-500">*</span></label>
          <input type="email" id="email" name="email" placeholder="name@gmail.com" required className="block w-full p-2 mb-6 border-b border-gray-500 bg-transparent text-white outline-none" onChange={handleChange} />
          
          <label htmlFor="phone" className="block text-white font-sans p-2">Phone</label>
          <input type="tel" id="phone" name="phone" placeholder="+1 234 ..." className="block w-full p-2 mb-6 border-b border-gray-500 bg-transparent text-white outline-none" onChange={handleChange} />
          
          <label htmlFor="message" className="block text-white font-sans p-2">Your message</label>
          <textarea id="message" name="message" placeholder="Your message..." className="block w-full p-2 mb-6 border-b border-gray-500 bg-transparent text-white outline-none" style={{ resize: 'vertical' }} onChange={handleChange}></textarea>
          <button type="submit" className="bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-700 font-sans">Submit</button>
        </form>
        
        <div className="contact-image lg:w-1/2">
          <img src='/images/contact.jpg' alt="Contact Monea" loading="lazy" className="hidden lg:block lg:w-full rounded-md" />
        </div>
      </div>    
    </main>
  );
};

export default Contact;