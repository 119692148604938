import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const People: React.FC = () => {
  return (
    <>
    <Helmet>
    <title>Monea | Our Team</title>
    </Helmet>
    <main className="flex flex-col py-5 px-10 lg:px-24 gap-12">
      <h1 className="text-white text-2xl text-center font-mono m-8">Our Team</h1>
        <div className="flex flex-col lg:flex-row items-center gap-16">
          <p className="text-white text-justify font-sans">
            At Monea Capital, we believe that our people are our greatest asset. Our team is made up of finance experts, blockchain professionals and software developers.
            <br /><br />
            Each member brings a unique perspective and skill set, contributing to the culture of collaboration and innovation that defines us.
            <br /><br />
            Our strategic vision and commitment to excellence guide Monea Capital's investments, always with the aim of achieving outstanding results for our clients. 
          </p>
          <img src='/images/people-1.jpg' alt="People Monea" loading="lazy" className="w-full lg:w-2/5 rounded-md" />
        </div>
      <h2 className="text-white text-2xl text-center font-mono lg:mb-8">Join Us</h2>
        <div className="flex flex-col lg:flex-row items-center">
          <p className="text-white text-justify font-sans lg:px-24">
            We give priority to the ongoing training and development of our team members.
            By fostering a culture of training and professional development, we ensure that our team remains at the forefront of industry trends and best practices. 
            <br /><br />
            We believe that the best investment solutions come from the collective intelligence and creativity of our team.
            Thus, we are always interested in hearing from new talent to bring fresh expertise and perspectives to Monea Capital.
            <br /><br />
            If you have a background in corporate finance, market finance, or programming, we encourage you to <Link className="text-white custom-link" to="/contact"><b>reach out</b></Link>. 
            Your unique skills and insights could be a valuable addition to our team, helping us to continually innovate and excel in the dynamic world of finance.
          </p>
        </div>
      <img src='/images/people-2.jpg' alt="People Monea" loading="lazy" className="mx-auto max-w-screen lg:w-1/2 h-auto rounded-md" />
    </main>
    </>
  );
};

export default People;