import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <header className="bg-black p-2.5 top-0 w-full box-border flex justify-center">
        <div className="header-container flex justify-between items-center w-full">
            <div className="logo">
                <Link to="/">
                    <img src='/images/MC-header.jpg' alt="Monea Capital" loading="lazy" className="h-11" />
                </Link>
            </div>
            <nav>
                <ul className="flex justify-center list-none p-0 m-0 flex-grow">
                    <li className="mx-4">
                        <Link to="/strategy" className="text-white no-underline font-medium font-mono text-xs hover:underline">Strategy</Link>
                    </li>
                    <li className="mx-4">
                        <Link to="/people" className="text-white no-underline font-medium font-mono text-xs hover:underline">People</Link>
                    </li>
                    <li className="mx-4">
                        <Link to="/contact" className="text-white no-underline font-medium font-mono text-xs hover:underline">Contact</Link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
  );
};

export default Header;