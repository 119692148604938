import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const Home: React.FC = () => {
  return (
    <>
    <Helmet>
    <title>Monea Capital</title>
    </Helmet>
    <main className="flex flex-col py-5 px-8 lg:px-24 gap-10">
      <h1 className="text-white text-2xl text-center font-mono m-10 px-16">Welcome to Monea Capital</h1>
        <div className="flex flex-col lg:flex-row items-center gap-16">
          <p className="text-white text-justify font-sans">
            Monea Capital is a distinguished portfolio management firm that caters to the unique needs of institutions and high-net-worth individuals. 
            Our approach is grounded in a purely active management strategy, which sets us apart in the financial industry.
            <br /><br />
            At Monea, we harness the power of sophisticated mathematical models, meticulously developed by our team of financial engineers. 
            These models are not only innovative but have been rigorously tried and tested over decades, ensuring their reliability and effectiveness in various market conditions.
            <br /><br />
            Our commitment to excellence and precision in portfolio management has earned us the trust of our clients, as we continually strive to optimize their investment outcomes through <Link className="text-white custom-link" to="/strategy"><strong>our strategies</strong></Link>.
            <br /><br />
          </p>
          <img src='/images/home-1.jpg' alt="Monea Capital" loading="lazy" className="w-full lg:w-2/5 rounded-md" />
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center gap-16">
          <img src='/images/home-2.jpg' alt="Monea Capital" loading="lazy" className="w-full lg:w-2/5 rounded-md" />
          <p className="text-white text-justify font-sans">
            With a customer-centric approach, Monea Capital offers customized investment solutions, tailored to each individual's specific objectives and risk tolerance.
            Our team is committed to ensuring transparency, integrity and exceptional service in every interaction.
            <br /><br />
            We offer a variety of models and strategies, each designed to match the unique profiles of our clients. While investor profiles may vary, our commitment to risk management and financial expertise remains the same across all our solutions.
          </p>
        </div>
    </main>
    </>
  );
};

export default Home;