import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white text-center p-2.5 w-full font-mono text-xs flex items-center justify-center box-border mt-5 border-t border-gray-800">
        &copy; 2024 Monea Capital. All rights reserved.
    </footer>
  );
};

export default Footer;