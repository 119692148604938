import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Monea - Error 404</title>
      </Helmet>
      <main className="container mx-auto p-4">
        <h1 className="text-white text-xl lg:text-2xl text-center font-mono m-10">Page not found</h1>
        <h2 className="text-white text-md lg:text-xl text-center font-mono mt-5">
          <Link to="/" className="text-decoration-line: underline">Click here to come back to the menu</Link>
        </h2>
        <div className="container flex justify-center">
          <img src='/images/404.jpg' alt="Error 404" loading="lazy" className="w-2/5 lg:w-1/3 mt-16" />
        </div>
      </main>
    </>
  );
};

export default NotFound;