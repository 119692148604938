import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Strategy: React.FC = () => {
  return (
    <>
    <Helmet>
    <title>Monea | Our Strategy</title>
    </Helmet>
    <main className="flex flex-col py-5 px-10 lg:px-24 gap-10">
      <h1 className="text-white text-2xl text-center font-mono m-10">Our Strategy</h1>
        <div className="flex flex-col lg:flex-row items-center gap-16">
          <p className="text-white text-justify font-sans">
            At Monea Capital, our investment strategy is built on the foundation of active management.
            We believe that by actively analyzing and adjusting our portfolios, we can seize opportunities and mitigate risks more effectively than a passive approach.
            <br /><br />
            This philosophy allows us to stay ahead of market trends and deliver superior returns for our clients.
            <br /><br />
            Risk management is a core component of our strategy. We employ advanced risk modeling techniques to monitor and manage the risk profile of our portfolios.
            By maintaining a disciplined approach to risk, we aim to protect our clients’ capital and achieve consistent, long-term growth.
          </p>
          <img src='/images/strategy-1.jpg'  alt="Our Strategy" loading="lazy" className="w-full lg:w-2/5 rounded-md" />
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center gap-16">
          <img src='/images/strategy-2.jpg' alt="Our Strategy" loading="lazy" className="w-full lg:w-2/5 rounded-md" />
          <p className="text-white text-justify font-sans">
            From the point of view of return on investment, we much prefer to obtain controlled and regular profits over time than to face high volatility in returns and therefore regular uncertainty for our customers.
            <br /><br />
            Our objective has always been to achieve a positive R.O.I. every year, whatever happens, even in difficult economic conditions or periods of high inflation.
            <br /><br />
            To find out more about our products and strategy, we encourage you to <Link className="text-white custom-link" to="/contact"><b>contact us</b></Link>.
          </p>
        </div>
    </main>
    </>
  );
};

export default Strategy;